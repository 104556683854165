.notFoundContainer {
  background-image: url('/assets/extends/notfoundbg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @apply flex flex-col w-full min-h-[100vh];
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 150px;
    @apply flex items-center py-6 px-36;
    .menu {
      @apply flex items-center justify-end w-full;
      ul {
        margin: 0;
        padding: 0;
        @apply m-0 p-0;
        li {
          list-style: none;
          float: left;
          margin-right: 30px;
          a,
          button {
            font-size: 15px;
            text-decoration: none;
            color: #000;
            &:hover {
              @apply text-bluesmart;
            }
          }
        }
      }
    }
  }
  .content {
    @apply flex flex-col justify-end items-center w-full pb-20 mt-auto;
    .imageWrapper {
      display: flex;
      @apply flex mb-20;
    }
    .title {
      @apply flex w-[45%] font-semibold text-center text-[60px];
    }
    .buttons {
      @apply flex mt-8;
      .buttonWrapper {
        @apply flex w-[100px] mr-6;
      }
    }
  }
  @media (max-width: 991.98px) {
    .content {
      .imageWrapper {
        margin-bottom: 160px;
      }
      .title {
        width: 50%;
      }
    }
  }
  @media (max-width: 479.99px) {
    .header {
      flex-direction: column;
      padding: 10px;
      .logoWrapper {
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
      }
      .menu {
        justify-content: center;
        align-items: center;
      }
    }
    .content {
      .title {
        width: 90%;
        font-size: 30px;
      }
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    .content {
      .imageWrapper {
        margin-bottom: 30px;
      }
      .title {
        width: 90%;
        font-size: 20px;
      }
    }
  }
}
